// About.js
import React from 'react';
import aboutImage from '../images/about.png'; // Update the path to your image

function About() {
    return (
        <div className="About">
            <img src={aboutImage} alt="About" className="About-image" />
        </div>
    );
}

export default About;
