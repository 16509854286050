import React, { useState, useEffect } from 'react';

const ScrambleText = ({ text, speed = 100, revealSpeed = 100 }) => {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
        const characters = '01234567890123456789!%^x()+-=[]{}|.<>/~';
        let scrambleIndex = 0;

        const scramble = () => {
            let scrambledText = '';
            for (let i = 0; i < text.length; i++) {
                if (i < scrambleIndex) {
                    scrambledText += text[i]; // Reveal the correct character
                } else {
                    scrambledText += characters.charAt(Math.floor(Math.random() * characters.length));
                }
            }
            setDisplayedText(scrambledText);
        };

        const revealCharacter = () => {
            if (scrambleIndex < text.length) {
                scrambleIndex++;
                scramble();
            } else {
                clearInterval(scrambleInterval);
            }
        };

        const scrambleInterval = setInterval(revealCharacter, revealSpeed);

        return () => {
            clearInterval(scrambleInterval);
        };
    }, [text, speed, revealSpeed]);

    return (
        <h1 style={{ fontFamily: 'monospace', whiteSpace: 'pre' }}>
            {displayedText}
        </h1>
    );
};

export default ScrambleText;
