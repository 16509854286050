// Khan.js
import React from 'react';
import khanGuide from '../images/Khan_guide.pdf';
import khanLogo from '../images/khan_logo.png';

function Khan() {
    return (
        <div className="Khan">
            <div className="Khan-logo-container">
                <a href="https://www.khanacademy.org/login" target="_blank" rel="noopener noreferrer">
                    <img src={khanLogo} alt="Khan Academy" className="Khan-logo" />
                </a>
            </div>
            <h1 className="Khan-heading">Khan Academy Sign In Guide</h1>
            <iframe
                src={`${khanGuide}#navpanes=0`}
                title="Khan Academy User Guide"
                className="Khan-pdf"
                frameBorder="0"
            />
        </div>
    );
}

export default Khan;
