import React, { useState } from 'react';
import Prodigy from '../images/prodigy_logo.png';
import Boddle from '../images/logo-boddle.png';
import pdfProdigy from '../images/prodigy_instructions.pdf';
import ScrambleText from './ScrambleText';


const MathResources = () => {
    const [selectedContent, setSelectedContent] = useState(null);

    const renderContent = () => {
        if (selectedContent === 'prodigy') {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <a href="https://play.prodigygame.com" target="_blank" rel="noopener noreferrer" className='Prodigy-login-link'>
                        Prodigy Student Log In
                    </a>
                    <h2>Prodigy Log In Instructions</h2>
                    <iframe src={`${pdfProdigy}#navpanes=0`} title="Prodigy Instructions" className="MathResources-pdf" />
                </div>
            );
        } else if (selectedContent === 'boddle') {
            return (
                <div>
                    <a href="https://www.boddlelearning.com/student-management/how-to-link-a-parent-and-student-account" target="_blank" rel="noopener noreferrer" className='Boddle-parent-link'>
                        How to Link a Parent and Student Account
                    </a>
                </div>
            );
        } else if (selectedContent === 'additional') {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <a href="https://access.openupresources.org/curricula/our6-8math/en/grade-6/family.html" target="_blank" rel="noopener noreferrer" className='Prodigy-login-link'>
                        Open-Up Resources
                    </a>
                    <a href="https://docs.google.com/document/d/1YBNBzlsluS9xmRJ632N5l9V3CJr8bZRHTbxpw6D4oWw/edit" target="_blank" rel="noopener noreferrer" className='Prodigy-login-link'>
                        6th Grade Math Video Summaries
                    </a>
                </div>
            );
        }
        return null; // No default render
    };

    return (
        <div className="MathResources">
            <div style={{ fontSize: '1.5rem' }}>
                <ScrambleText text="Math Resources:" speed={50} revealSpeed={100} />
            </div>

            <div className="MathResources-button-container">
                <button onClick={() => setSelectedContent('prodigy')}>
                    <img src={Prodigy} alt="Prodigy link" className="MathResources-logo image-button" />
                </button>
                <button onClick={() => setSelectedContent('boddle')}>
                    <img src={Boddle} alt="Boddle link" style={{ marginTop: "-10px" }} className="MathResources-logo image-button" />
                </button>
                <button onClick={() => setSelectedContent('additional')} className="MathResources-additional">
                    Additional Resources
                </button>
            </div>

            {renderContent()}
        </div>
    );
};

export default MathResources;
