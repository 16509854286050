import React, { useState } from 'react';
import canvasLogoSL from '../images/button_studentcanvas.png';
import canvasLogoPL from '../images/button_parentcanvas.png';
import pdf1 from '../images/ParentObserverCanvasLogin.pdf';
import pdf2 from '../images/StudentLoginCanvas.pdf';
import pdf3 from '../images/CreatePairWithObserverCode.pdf';

function Canvas() {
    const [selectedContent, setSelectedContent] = useState('iframe3'); // Default to the first PDF

    const renderContent = () => {
        if (selectedContent === 'iframe3') {
            return (
                <div className='Canvas-links-image-container'>
                    <a href="https://jsd.instructure.com/login/saml" target="_blank" rel="noopener noreferrer">
                        <img src={canvasLogoSL} alt="Canvas" className="Canvas-logo" />
                    </a>
                    <a href="https://jsd.instructure.com/login/canvas" target="_blank" rel="noopener noreferrer">
                        <img src={canvasLogoPL} alt="Canvas" className="Canvas-logo" />
                    </a>

                </div>
            );
        }
        else if (selectedContent === 'pdf1') {
            return <iframe src={`${pdf1}#navpanes=0`} title="Canvas User Guide 1" className="Canvas-pdf" />;
        } else if (selectedContent === 'pdf2') {
            return <iframe src={`${pdf2}#navpanes=0`} title="Canvas User Guide 2" className="Canvas-pdf" />;
        }
        else if (selectedContent === 'pdf3') {
            return <iframe src={`${pdf3}#navpanes=0`} title="Canvas User Guide 4" className="Canvas-pdf" />;
        }
    };

    return (
        <div className="Canvas">

            <h1 className="Canvas-heading">Canvas User Guides</h1>

            <div className="Canvas-button-container">
                <button onClick={() => setSelectedContent('iframe3')}>Log In Links</button>
                <button onClick={() => setSelectedContent('pdf1')}>Parent Sign Up Instructions</button>
                <button onClick={() => setSelectedContent('pdf2')}>Student Log In Instructions</button>
                <button onClick={() => setSelectedContent('pdf3')}>Pairing Code</button>
            </div>

            {renderContent()}
        </div>
    );
}

export default Canvas;
