import React from 'react';
import wishlistImage from '../images/Amazon-wishlist-logo.png'; // Update the path to your image

function WishList() {
    return (
        <div className="WishList">
            <div className="WishList-container">
                {/* Section for Amazon Wishlist */}
                <div className="WishList-section">
                    <h2>Wish List:</h2>
                    <a
                        href="https://www.amazon.com/hz/wishlist/ls/2K4PO9OWNMQYB?ref_=wl_share"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={wishlistImage} alt="Amazon Wish List" className="WishList-image" />
                    </a>
                </div>

                {/* Section for Items Always Needed */}
                <div className="WishList-section">
                    <h2>Always Needed:</h2>
                    <ul className="WishList-links">
                        <li><a href="https://www.amazon.com/dp/B00KA25G7A/?coliid=I1IQPSTQ7OS4AH&colid=2K4PO9OWNMQYB&ref_=list_c_wl_lv_ov_lig_dp_it&th=1" target="_blank" rel="noopener noreferrer">Tissues</a></li>
                        <li><a href="https://www.amazon.com/dp/B001F03SDA/?coliid=IHIL0T3M154HG&colid=2K4PO9OWNMQYB&psc=1&ref_=list_c_wl_lv_ov_lig_dp_it" target="_blank" rel="noopener noreferrer">Disinfecting Wipes</a></li>
                        <li><a href="https://www.amazon.com/dp/B09HHD93VB/?coliid=I3UU3325BHOY7N&colid=2K4PO9OWNMQYB&psc=1&ref_=list_c_wl_lv_ov_lig_dp_it" target="_blank" rel="noopener noreferrer">Hand Sanitizer Large</a></li>
                        <li><a href="https://www.amazon.com/Germ-X-Advanced-Hand-Sanitizer-Aloe/dp/B078J5682K/ref=sr_1_5?crid=7FYUOUFP4CI8&dib=eyJ2IjoiMSJ9.t4DrtCFm2EnMIpascsKDxbPlxcT_sL-1zi1h04BZZUE.zVbQshNrQ-Wrpe-Eix-nRdQxUbMsqtuUvqcJBhUraTY&dib_tag=se&keywords=Germ-X+Advanced+Hand+Sanitizer+with+Aloe+and+Vitamin+E%2C+Kids+Hand+Sanitizer%2C+Moisturizing+Gel%2C+Instant+and+No+Rinse+Formula%2C+Pump+Bottle%2C+Back+to+School+Supplies%2C+1+Liter&qid=1723994081&sprefix=germ-x+advanced+hand+sanitizer+with+aloe+and+vitamin+e%2C+kids+hand+sanitizer%2C+moisturizing+gel%2C+instant+and+no+rinse+formula%2C+pump+bottle%2C+back+to+school+supplies%2C+1+liter%2Caps%2C263&sr=8-5" target="_blank" rel="noopener noreferrer">Hand Sanitizer Small</a></li>
                        <li><a href="https://www.amazon.com/TooyBing-Washable-Coloring-Classroom-Supplies/dp/B0D2CN64Y1/ref=sr_1_40?crid=3PHK2VJ4UQQHV&dib=eyJ2IjoiMSJ9.PTEHVpgxEcdnwRF0_zOXE88R4kvI-j865Kpr5ppmzsr-k3me5Rj2AwrQWwcjlB9mEbnFAatDlYAwLGQ3MLb-Anulsi1_4ZUHoC2GxoSHXq1DXIzOMKleUblUIUBU7-iGixnxh-93Kw1lg3C6Q3_TCVe4bJJqgb28LUPHuaHhvGecSYPt8JImlTB_STOVE2PUI3cOO4SAKYlpzutRr73ITkn6Ty5ptFPxfiXJctiYpJAieACZR6BGT2OTSU9nDWrBXtHAeqwnRf6e_kZRGE6Cp4W7WLG6LPSr0SZsb8NufsE.gmcdHYFer1rjLoLRhH2tdRDFyRryUlKOOaKKloTXHYk&dib_tag=se&keywords=art+markers+for+kids&qid=1723935763&sprefix=art+markers%2Caps%2C154&sr=8-40" target="_blank" rel="noopener noreferrer">Art Markers</a></li>
                        <li><a href="https://www.amazon.com/Magnetic-Dealkits-Whiteboard-Supplies-Classroom/dp/B07Q5WKR71/ref=sr_1_10?crid=1ZEXCUN4EYKYE&dib=eyJ2IjoiMSJ9.hqmfnkeFKhnGX-LChFBgcDv1j1jFFeT6XnbAgdfUdyDs_cy7-Wg6Tr6PMJnlEuZ8KuYF3NA_BPUMqOu_reHl10pSzqbgY4-L1ySFUyNJ9m2llCsXgsmx-xWbt2hqNfiBKENiNldZ30w5wz4tzEd2psXVf11UGGzM7TtVC6lol2DdM5cf6ClC9vTlX-f_DElOauZqhanAR_bSQRizhfUqWeDafoUUkk6nTBmFvMAL6Yg5PIBfhuIi7TRlIxyGdOacl_VDtpHSoqqZCHWPq0rJTGxPSOe7-7C-Y002BFU0zl0.pyqdFSwEIQpzKgkZeyvmQjX9Yvuk9HA0OZsbp564NoY&dib_tag=se&keywords=skinny%2Bdry%2Berase%2Bmarkers&qid=1723935872&sprefix=skinny%2Bdry%2B%2Caps%2C214&sr=8-10&th=1" target="_blank" rel="noopener noreferrer">Fine Point Dry Erase Markers</a></li>
                        <li><a href="https://www.amazon.com/Colored-Pencils-Presharpened-classroom-supplies/dp/B08XXDFRDM/ref=sr_1_36?crid=Q1NGWFWY4M6H&dib=eyJ2IjoiMSJ9.I_cgvZJTj_uxwAW1XVgC4DUbETc1KB3vSGSbPTw_3hwrQ9KprbbJMVBNIqH1UW9dbxqGC4yAydSmTk2YTh0RK8D29vFeotB_1lcoKFoRQMw0rDlwh2pJbTrZjN2fxeqS9nnak5TYKZx-ZhmalC4T2CB3wjFsSKRosrpHWDElOuAWRnSi5swqNCKxg1ZPqFZd1Z1j3Ar8xA299N0DSz8nMyHi3B_DC5sbv4-nYYWUUbEUj9OOGvycgtzFLbhOY8LPTVBvM66gGR1jUN6YvVL_KGuJWrTmAbMkAMRWctd5RCY.hKJIEQmxWF-BWdDBrKIAH2bo8yUc7RAlje7iHW7NaPs&dib_tag=se&keywords=colored%2Bpencils&qid=1723936049&sprefix=colored%2Bpencil%2Caps%2C179&sr=8-36&th=1" target="_blank" rel="noopener noreferrer">Colored Pencils</a></li>
                        <li><a href="https://www.amazon.com/Sharpie-27145-Highlighters-Assorted-12-Count/dp/B002BA5WMI/ref=sr_1_6?crid=1YWPJC995Y0CU&dib=eyJ2IjoiMSJ9.-N0elg2MMV8tL6Ka_eR5MmhY0xWKej7NoUHW7Z1qpm5K326TInxo8aXjYIC2GSPJo6HxzzpyUaxm8Pye0Ro9oqHUsqj47G938VkzTdi6lhM1g6tMcg6gizqxqaDemNf7Bzuj1t_JcWMY-AGoXyUt2BlSSlT04vJx8Dz11GJhhGhdBs0SNMoQh8clWnu0ViUtQ1f3KDmGKWuYrnEwtPsvVwtzAK6MA2kq-VoghIEMlqOIlS0DWMsvqnsA1lbknX_RdZjJod2mI61NOUOAmGUqsFjEISXzHVSGeEuWQz9LmdY.uPlc3cxyNnE_E9VlkPQB9bzR5KODcrEIsvrM6_RTnDY&dib_tag=se&keywords=highlighters&qid=1723936144&sprefix=highlighter%2Caps%2C192&sr=8-6&th=1" target="_blank" rel="noopener noreferrer">Highlighters</a></li>

                        {/* Add more items as needed */}
                    </ul>
                    <h2>Suggested Items for Your Child:</h2>
                    <ul className="WishList-links">
                        <li><a href="https://www.amazon.com/Wireless-Vssoplor-Portable-Computer-Computer-Black/dp/B098S48QWM/ref=sr_1_6?crid=310LZ7JBAH8BD&dib=eyJ2IjoiMSJ9.5ImJyMbbi4eC20AvYHbPTZ0dROVJg4b8BnDZD7WpJc5hcNYKKkEd_J9O-s0oShtOXplcBXffZ3XY9qa6wwu0Ci-B_kxQgjBkgz7feHuVCQkcxYGIXfaBhlRDOMLPSAJOr7e_wlbGZcmiadsws3WLCtTP-Bc2hw6ggIALnLbnzsLJRAVpghuBhzuyZFAWlvkhHf7JnobBfuws_o6Mi5vh6pXrFgxlaWhcA1W4_6W7f40.XuawWroCfZUypxn84NRYRtfUHVxOkyu7dFdLLd6-utg&dib_tag=se&keywords=mouse&qid=1723936228&sprefix=mou%2Caps%2C290&sr=8-6&th=1" target="_blank" rel="noopener noreferrer">Mouse</a></li>
                        <li><a href="https://www.amazon.com/gp/product/B00NJ2M33I/ref=ox_sc_act_image_1?smid=ATVPDKIKX0DER&th=1" target="_blank" rel="noopener noreferrer">Headphones (if preferred over provided earbuds)</a></li>
                        <li><a href="https://www.amazon.com/gp/product/B08FFKBX8G/ref=sw_img_1?smid=APH4LUQ0KYO9D&psc=1" target="_blank" rel="noopener noreferrer">LCD Writing Tablet</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default WishList;
