// Khan.js
import React from 'react';
import disclosure from '../images/Disclosure.pdf';


function Disclosure() {
    return (
        <div className="Khan">

            <h1 className="Khan-heading">Mrs Krans Disclosure</h1>
            <iframe
                src={`${disclosure}#navpanes=0`}
                title="Mrs Krans Disclosure"
                className="Khan-pdf"
            />
        </div>
    );
}

export default Disclosure;