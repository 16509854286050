// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './images/flower1.png';
import './App.css';
import Menu from './components/Menu';
import About from './components/About';
import Khan from './components/Khan';
import WishList from './components/WishList2';
import Canvas from './components/Canvas';
import Disclosure from './components/Disclosure';
import Math from './components/MathResources';
import Footer from './components/Footer';
import WitAndWisdom from './components/WitAndWisdom';
import Newsletter from './components/Newsletter';
// Import other components as needed

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Link to="/" className="Header-link"> {/* Add this Link wrapper */}
            <div className="Header-content">
              <img src={logo} className="App-logo" alt="logo" />
              <p style={{ fontFamily: 'Honk, sans-serif', fontSize: '4.5rem' }}>Mrs. Krans</p>
            </div>
          </Link>
          <Menu />
        </header>
        <main className="App-main">
          <Routes>

            <Route path="/" element={<Newsletter />} />
            <Route path="/about" element={<About />} />
            <Route path="/khanacademy" element={<Khan />} />
            <Route path="/canvas" element={<Canvas />} />
            <Route path="/disclosure" element={<Disclosure />} />
            <Route path="/mathresources" element={<Math />} />
            <Route path="/witandwisdom" element={<WitAndWisdom />} />
            <Route path="/wishlist" element={<WishList />} />
            {/* Add more routes for other components */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}


export default App;
