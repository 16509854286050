import React from 'react';

const Footer = () => {
    return (
        <footer className="Footer">
            <p>&copy; 2024 Krans. All rights reserved.</p>
            <p>Christar.Krans@JordanDistrict.org</p>
            <p>School Phone: 801-567-8950</p>
        </footer>
    );
};

export default Footer;
