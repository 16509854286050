import React from 'react';
import WitAndWisdomVideo from '../images/ww-video.mp4';

const WitAndWisdom = () => {
    return (
        <div className="WitAndWisdom">
            <h1>Wit & Wisdom</h1>
            <p>Our district is using Wit & Wisdom for our English Language Arts curriculum. Below you'll find links to the different module family resources, along with links to the books on Amazon if you're interested in purchasing a copy for your child to be able to take notes and highlight in the book. We do have a class set; however, students will not be able to write in the class set book or take them home.</p>

            <p className='note'>**Note: We may not make it through all 4 modules throughout the school year.</p>

            <video width="80%" controls>
                <source src={WitAndWisdomVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className="ww-resources">
                <p><a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1qNkIzkW5FglOClt5pawr10AWD-jlWigp/view?usp=sharing" className="ww-link">Wit & Wisdom Family Welcome Letter</a> (<a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1EoRYz3V0fAVX1Or1T69o8HDQyH3gZwNG/view?usp=sharing" className="ww-link">Spanish </a>)</p>
                <p><a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1PJJ3Xf4tEyHCYmAgrSSrjCY3mQ8T3aAx/view?usp=sharing" className="ww-link">Module 1 Family Tip Sheet</a> (<a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1837vxusKqFem_63uqSzZ9_OagJ8fpG_W/view?usp=sharing" className="ww-link">Spanish </a>)</p>
                <ul className="ww-resources-list">
                    <li>Bud, Not Buddy (<a target="_blank" rel="noreferrer" href="https://www.amazon.com/Bud-Buddy-Christopher-Paul-Curtis/dp/0440413281/ref=sr_1_1?crid=3HEVE3W2SPMR0&dib=eyJ2IjoiMSJ9.RtZx_oYMr5Sdw4AnD0Z-bYE4UzIMxExMjuGmOH9nznXlAwwNeKr_i8N2xZr6e9Fmyjj4WumB7IvOGLwx7W_PZvYhOv3IxFZh3UP8Nsh6QZSerUSKP70Qrgj_GLFfSM4pwk4IrfKq0hNmnk3llrOWk62etMI0KYjdkm21vqZ3FD_XmPIukRE-i6WCC-vG19W5HSPuXMOoYO9ydWKEMCtmlDocq0iCoERrQ7jyrWP41OM.BKMwPuPnVoiL2HAigpsh340VdgJ8Hq9eUqjS6jAgv94&dib_tag=se&keywords=bud+not+buddy&qid=1723931748&sprefix=bud+not+buddy%2Caps%2C223&sr=8-1" className="ww-link">Amazon link </a>)</li>
                    <li>Out of the Dust (<a target="_blank" rel="noreferrer" href="https://www.amazon.com/Out-Dust-Karen-Hesse/dp/0590371258/ref=sr_1_1?crid=2CXUOR78ZQP4&dib=eyJ2IjoiMSJ9.P-g8fzQjeH6N8O_pf0m9yVz1rUn0hdCBzbOrVsce5BYcxf42UPskJgNh62vpXic2OvZbGq8fRshytajEN8kQQOMppp9YN7Q3YNc7IWfpIlZogLamyuxcwWRyR4Vh1HRGnGEcOHyrg3qd9HPPy_cCsG99ElugVUGeIMJXXCjIofYMkd7vkPW-OdrWAKdPzrIQIysE1GtY6D_QPyDGHiMNZN6UOpZNJ4ppsO6Bac9RWQU.tHQlAJNqWiRv8L6TjqHBlvbEdcEvl31pukh0sMEipdA&dib_tag=se&keywords=out+of+the+dust&qid=1723931845&sprefix=out+of+the+dust%2Caps%2C214&sr=8-1" className="ww-link">Amazon link </a>)</li>
                </ul>
                <p><a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1fBEJNUeY-G5RkWJylSrb-rkfq0x8p4OB/view?usp=sharing" className="ww-link">Module 2 Family Tip Sheet</a> (<a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/17KIFc5nGi1UGtjrS0x7fweQTwdh9cEWZ/view?usp=sharing" className="ww-link">Spanish </a>)</p>
                <ul className="ww-resources-list">
                    <li>The Odyssey (<a target="_blank" rel="noreferrer" href="https://www.amazon.com/Odyssey-Gillian-Cross/dp/0763647918/ref=sr_1_1?crid=1AS5SNWKY7ZY2&dib=eyJ2IjoiMSJ9.B6MsL_KIG9MYHVgcZusvuaLDrsZTSmKbgi-mN0zG2gNsQkXzOL0OvSReqz9Z7xq5-d5lrmFwrx3JIHaMu2qKGJBNBbUKgA0m_q84M7SlUz0ctLJL6j-i_kv-6LaXwS9bPjGn-Twr6zoT_dG5FzYrRg.ijGA-Ue5089DaGjvdSue1-4uNBFWbBQ6AzoyOLbtR_w&dib_tag=se&keywords=the+odyssey+gillian+cross&qid=1723931900&sprefix=the+odyssey+gillia%2Caps%2C208&sr=8-1" className="ww-link">Amazon link </a>)</li>
                    <li>Ramayana Divine: Divine Loophole (<a target="_blank" rel="noreferrer" href="https://www.amazon.com/Ramayana-Divine-Loophole-Sanjay-Patel/dp/081187107X/ref=sr_1_1?crid=3V37Y76JZEAA3&dib=eyJ2IjoiMSJ9.VNDO4p0D4BjN8qxJC38nUYBXG8oPTg35A-7PqswYQMvkSBbDDeLtVnCd_es7uZob.bzJObA-r9_IqRSBmmuG1Za86wLHGVTzAM1j44MFlgPE&dib_tag=se&keywords=Ramayana%3A+Divine+Loophole&qid=1723931990&sprefix=ramayana+divine+loophole%2Caps%2C272&sr=8-1" className="ww-link">Amazon link</a>)</li>
                </ul>
                <p><a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1TZIvI6_brmCTTgdg9vZaNbe4C0XfsVZw/view?usp=sharing" className="ww-link">Module 3 Family Tip Sheet</a> (<a href="https://drive.google.com/file/d/1McmpjzqC5S5nrM61EiE08RkBZ2wNEsJz/view?usp=sharing" className="ww-link">Spanish </a>)</p>
                <ul className="ww-resources-list">
                    <li>Blood on the River: Jamestown, 1607 (<a target="_blank" rel="noreferrer" href="https://www.amazon.com/Blood-River-James-Town-1607/dp/0142409324/ref=sr_1_1?crid=JVOHP7GIOE9F&dib=eyJ2IjoiMSJ9.I8mV1157cHVE3LqYvj0GNgVxLpKPukPlMFjvT6QJpxDvArnI-jFw41RXONped6zk.g6rctLmnzeInKjAtK_f06XAY_M1IG-S11-hDMwdEQM8&dib_tag=se&keywords=Blood+on+the+River%3A+Jamestown+1607%2C+Elisa+Carbone&qid=1723932075&sprefix=blood+on+the+river+jamestown+1607%2C+elisa+carbone%2Caps%2C299&sr=8-1" className="ww-link"> Amazon link </a>)</li>
                    <li>Written in Bone: Buried Lives of Jamestown and Colonial Maryland (<a target="_blank" rel="noreferrer" href="https://www.amazon.com/Written-Bone-Jamestown-Exceptional-Intermediate/dp/0822571358/ref=sr_1_1?crid=XNPP5ROX9M77&dib=eyJ2IjoiMSJ9.vyEX3rVgqE1Eznv3lPWPGQ.sc8NZQlX1yMw0T9Wk-uFcTVi7rM2Xo0e3Zih1R5qIaA&dib_tag=se&keywords=Written+in+Bone%3A+Buried+Lives+of+Jamestown+and+Colonial+Maryland%2C+Sally+Walker&qid=1723932159&sprefix=written+in+bone+buried+lives+of+jamestown+and+colonial+maryland%2C+sally+walker%2Caps%2C284&sr=8-1" className="ww-link">Amazon link </a>)</li>
                </ul>
                <p><a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1HLQ7ICpa2oNc1ZLwHUuDl3YFHhMl3Hx7/view?usp=sharing" className="ww-link">Module 4 Family Tip Sheet</a> (<a href="https://drive.google.com/file/d/1AHD7G3I0oGV60CohnS3UJTN8xgFRa-ZA/view?usp=sharing" className="ww-link">Spanish </a>)</p>
                <ul className="ww-resources-list">
                    <li>Shipwreck at the Bottom of the World: The Extraordinary True Story of Shackleton and the Endurance (<a target="_blank" rel="noreferrer" href="https://www.amazon.com/Shipwreck-Bottom-World-Extraordinary-Shackleton/dp/0375810498/ref=sr_1_1?crid=1GRCKWM8Z7GS6&dib=eyJ2IjoiMSJ9.KdVhlEbFl2HhEKAFXdPA4Lg1TD5oqoVrOxtpNhBP4mzGjHj071QN20LucGBJIEps.3zdXT1qo0B0qokQONp1JM_Av8Ef3Ic_LN7P-ucGoGgI&dib_tag=se&keywords=Shipwreck+at+the+Bottom+of+the+World%3A+The+Extraordinary+True+Story+of+Shackleton+and+the+Endurance%2C+Jennifer+Armstrong&qid=1723932300&sprefix=shipwreck+at+the+bottom+of+the+world+the+extraordinary+true+story+of+shackleton+and+the+endurance%2C+jennifer+armstrong%2Caps%2C276&sr=8-1" className="ww-link">Amazon link </a>)</li>
                    <li>I Am Malala: How One Girl Stood Up for Education and Changed the World (<a target="_blank" rel="noreferrer" href="https://www.amazon.com/Am-Malala-Education-Changed-Readers/dp/0316327913/ref=sr_1_1?crid=3FHE36EJAMWWB&dib=eyJ2IjoiMSJ9.fShoGF6_EVmQKrs5EuVc9A.6yK2q6RsoSLmzQ5mZlSq_P7MbN-1KNFQHTdfJnrFnOg&dib_tag=se&keywords=I+Am+Malala%3A+How+One+Girl+Stood+Up+for+Education+and+Changed+the+World%2C+Malala+Yousafzai+and+Patricia+McCormick&qid=1723932353&sprefix=i+am+malala+how+one+girl+stood+up+for+education+and+changed+the+world%2C+malala+yousafzai+and+patricia+mccormick%2Caps%2C340&sr=8-1" className="ww-link">Amazon link </a>)</li>
                </ul>
            </div>
        </div>
    );
};

export default WitAndWisdom;
