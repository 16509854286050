// Menu.js
import React from 'react';
import { Link } from 'react-router-dom';

function Menu() {
    const menuItems = [
        { name: "About Me", path: "/about" },
        { name: "Canvas", path: "/canvas" },
        { name: "Khan Academy", path: "/khanacademy" },
        { name: "Wish List", path: "/wishlist" }
    ];

    const dropdownItems = [
        { name: "Math Resources", path: "/mathresources" },
        { name: "Wit and Wisdom", path: "/witandwisdom" },
        { name: "Disclosure", path: "/disclosure" },
    ];

    return (
        <nav className="Menu">
            {menuItems.map((item, index) => (
                <Link key={index} to={item.path} className="Menu-item">
                    {item.name}
                </Link>
            ))}

            <div className="Dropdown">
                <button className="Dropdown-button">Additional Resources</button>
                <div className="Dropdown-content">
                    {dropdownItems.map((item, index) => (
                        <Link key={index} to={item.path} className="Dropdown-item">
                            {item.name}
                        </Link>
                    ))}
                </div>
            </div>
        </nav>
    );
}

export default Menu;
